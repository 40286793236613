import React, { FC, memo } from "react";
import { Rect, Text } from "react-konva";
import { Theme } from "./Themes";

interface ICellViewProps {
  posX: number;
  posY: number;
  side: number;
  color: string;
  text: string | null;
  theme: Theme;
}

export const CellView: FC<ICellViewProps> = memo((props) => {
  const { theme, posX, posY, side, color, text } = props;

  return (
    <>
      <Rect
        perfectDrawEnabled={false}
        x={posX}
        y={posY}
        width={side}
        height={side}
        stroke={theme.Colors.BORDER_COLOR}
        strokeWidth={1}
        strokeHitEnabled={false}
        shadowForStrokeEnabled={false}
        strokeScaleEnabled={false}
        fill={color}
      />
      {text != null && (
        <Text
          text={text}
          x={posX}
          y={posY}
          width={side}
          height={side}
          align="center"
          verticalAlign="middle"
          fontSize={side * 0.5}
          perfectDrawEnabled={false}
          listening={false}
          fillColor={"red"}
        />
      )}
    </>
  );
});
