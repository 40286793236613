import { useEffect, useState } from "react";
import { IPoint } from "./Field";

function getWindowSize(): IPoint {
  return {
    y: window.innerHeight,
    x: window.innerWidth,
  };
}

export function useWindowSize(): IPoint {
  const [size, setSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => setSize(getWindowSize());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  return size;
}
