import React, { FC } from "react";
import { Group, Text } from "react-konva";
import { useTheme } from "./Themes";
import { IPoint } from "./Field";

interface IBannerProps {
  success: boolean;
  position: IPoint;
  size: IPoint;
}

export const Banner: FC<IBannerProps> = ({ success, position, size }) => {
  const theme = useTheme();

  const text = success ? theme.Texts.SUCCESS : theme.Texts.FAILURE;
  const fill = success
    ? theme.Colors.WON_TEXT_COLOR
    : theme.Colors.LOST_TEXT_COLOR;

  return (
    <Group>
      <Text
        text={text}
        x={position.x}
        y={position.y}
        width={size.x}
        height={size.y}
        fontSize={size.y / 2}
        fill={fill}
        align="center"
        verticalAlign="middle"
      />
    </Group>
  );
};
