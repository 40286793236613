import React, { FC, useCallback, useMemo } from "react";
import { Layer, Stage } from "react-konva";
import { Banner } from "./Banner";
import { Cell } from "./Cell";
import { GameState, IPoint, sizeOf } from "./Field";
import { useMinefield } from "./useMinefield";
import { useWindowSize } from "./useWindowSize";
import { useTheme, useThemeToggle } from "./Themes";
import Konva from "konva";

function calculateSide(windowSize: IPoint, fieldSize: IPoint): number {
  return Math.min(
    Math.round(windowSize.x / (fieldSize.x + 2)),
    Math.round(windowSize.y / (fieldSize.y + 2))
  );
}

function calculateWindowPaddings(
  windowSize: IPoint,
  fieldSize: IPoint,
  side: number
): IPoint {
  return {
    x: Math.round((windowSize.x - fieldSize.x * side) / 2),
    y: Math.round((windowSize.y - fieldSize.y * side) / 2),
  };
}

function flat2dMap<T>(size: IPoint, cb: (x: number, y: number) => T): T[] {
  const cells = [];
  cells.length = size.x * size.y;

  let i = 0;
  for (let y = 0; y < size.y; y++) {
    for (let x = 0; x < size.x; x++) {
      cells[i++] = cb(x, y);
    }
  }

  return cells;
}

export const MineSweeper: FC = () => {
  const windowSize = useWindowSize();
  const { field, gameState, open, toggle, restart } = useMinefield(windowSize);

  const fieldSize = sizeOf(field);
  const side = useMemo(
    () => calculateSide(windowSize, fieldSize),
    [windowSize, fieldSize]
  );
  const margins = useMemo(
    () => calculateWindowPaddings(windowSize, fieldSize, side),
    [windowSize, fieldSize, side]
  );
  const endGame = gameState !== GameState.RUNNING;
  const restartIfEnd = useCallback(
    (ev: Konva.KonvaEventObject<any>) => {
      ev.cancelBubble = true;
      if (endGame) restart();
    },
    [endGame, restart]
  );
  const theme = useTheme();
  const themeToggle = useThemeToggle();

  const cells = flat2dMap(fieldSize, (x, y) => (
    <Cell
      theme={theme}
      x={x}
      y={y}
      endGame={endGame}
      key={`${x}_${y}`}
      cell={field[y][x]}
      margins={margins}
      side={side}
      onOpen={open}
      onToggle={toggle}
    />
  ));

  return (
    <Stage
      style={{ background: theme.Colors.MAIN_BACKGROUND }}
      width={windowSize.x - 1}
      height={windowSize.y - 1}
      onClick={themeToggle}
    >
      <Layer onClick={restartIfEnd} onTap={restartIfEnd}>
        {cells}
      </Layer>
      {endGame && (
        <Layer>
          <Banner
            success={gameState === GameState.WON}
            position={{ x: margins.x, y: 0 }}
            size={{ x: side * fieldSize.x, y: Math.min(margins.y, side * 2) }}
          />
        </Layer>
      )}
    </Stage>
  );
};
