import React, { FC, useContext } from "react";
import { useLocalStorage } from "./useLocalStorage";

export interface Theme {
  Colors: typeof LightColors;
  Texts: typeof Texts;
  Icons: typeof Icons;
}

const LightColors = {
  LOST_TEXT_COLOR: "#FF4136",
  WON_TEXT_COLOR: "#2ECC40",
  OPENED_BG_COLOR: "#2ECC40",
  MAIN_MINE_BG_COLOR: "#FF9A9A",
  MINE_BG_COLOR: "#EEDDDD",
  FLAG_BG_COLOR: "#FF851B",
  DEFAULT_BG_COLOR: "#AAAAAA",
  BORDER_COLOR: "#111111",
  MAIN_BACKGROUND: "#EEEEEE",
  TEXT_COLOR: "#111111",
};

const DarkColors = {
  LOST_TEXT_COLOR: "#990e12",
  WON_TEXT_COLOR: "#106426",
  OPENED_BG_COLOR: "#38616d",
  MAIN_MINE_BG_COLOR: "#004256",
  MINE_BG_COLOR: "#004256",
  FLAG_BG_COLOR: "#6c939f",
  DEFAULT_BG_COLOR: "#002631",
  BORDER_COLOR: "#004256",
  MAIN_BACKGROUND: "#111111",
  TEXT_COLOR: "#111111",
};

const Texts = {
  SUCCESS: "That was tough, but you made it!",
  FAILURE: "My naym is snek, my bodi long, my hed is smol, but jaw is stronk!",
};

const Icons = {
  MAIN_MINE: "🐉",
  MINE: "🐍",
  FLAG: "🚩",
};

type ThemeType = "light" | "dark";

interface ThemeContextState {
  theme: ThemeType;
  toggle: () => void;
}

const ThemeContext = React.createContext<ThemeContextState>({
  theme: "dark",
  toggle() {},
});

export const ThemeProvider: FC = ({ children }) => {
  const defaultTheme = window.matchMedia?.("(prefers-color-scheme: dark)")
    ?.matches
    ? "dark"
    : "light";

  const [theme, setTheme] = useLocalStorage<ThemeType>("theme", defaultTheme);
  const contextValue = {
    theme,
    toggle: () => setTheme((old) => (old === "light" ? "dark" : "light")),
  };

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};

export function useTheme() {
  const { theme } = useContext(ThemeContext);
  return {
    Colors: theme === "light" ? LightColors : DarkColors,
    Icons: Icons,
    Texts: Texts,
  };
}

export function useThemeToggle() {
  const { toggle } = useContext(ThemeContext);
  return toggle;
}
