import { useCallback, useState } from "react";
import {
  createField,
  Field,
  GameState,
  IPoint,
  openCell,
  toggleFlagOnCell,
} from "./Field";

interface IMinefield {
  field: Field;
  gameState: GameState;
  open: (p: IPoint) => void;
  toggle: (p: IPoint) => void;
  restart: () => void;
}

const minSize = 7;
const defaultSide = 80;

function calculateSize(windowsSize: IPoint): IPoint {
  const x = Math.floor(windowsSize.x / defaultSide);
  const y = Math.floor(windowsSize.y / defaultSide);

  const min = Math.min(x, y);
  if (Math.min(x, y) < minSize) {
    const rate = minSize / min;
    return {
      x: Math.floor(x * rate),
      y: Math.floor(y * rate),
    };
  }

  return { x, y };
}

function calculateMines(size: IPoint, rate = 8.1): number {
  return Math.round((size.x * size.y) / rate);
}

export function useMinefield(screenSize: IPoint): IMinefield {
  const [turns, setTurns] = useState(0);
  const [field, setField] = useState(() => {
    const size = calculateSize(screenSize);
    return createField(size, calculateMines(size));
  });
  const [gameState, setGameState] = useState(GameState.RUNNING);

  const restart = useCallback(() => {
    const size = calculateSize(screenSize);
    setField(createField(size, calculateMines(size)));
    setGameState(GameState.RUNNING);
  }, [screenSize]);

  const open = useCallback(
    (p: IPoint) => {
      let gameField = field;

      if (gameField[p.y][p.x].isMine && turns === 0) {
        console.log("Mine on the first turn");
        const size = { y: field.length, x: field[0].length };

        while (gameField[p.y][p.x].isMine) {
          console.log("Recreating field");
          gameField = createField(size, calculateMines(size));
        }
      }

      setGameState(openCell(gameField, p));
      setField([...gameField]);
      setTurns((t) => t + 1);
    },
    [field, turns]
  );

  const toggle = useCallback(
    (p: IPoint) => {
      toggleFlagOnCell(field, p);
      setField([...field]);
    },
    [field]
  );

  return {
    field,
    gameState,
    open,
    toggle,
    restart,
  };
}
