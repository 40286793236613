export enum CellState {
  UNKNOWN,
  OPENED,
  FLAGGED,
}

export enum GameState {
  RUNNING,
  WON,
  LOST,
}

export interface ICell {
  isMine: boolean;
  minesAround: number;
  state: CellState;
}

export type Field = ICell[][];

export interface IPoint {
  x: number;
  y: number;
}

export function sizeOf(field: Field): IPoint {
  const width = field[0].length;
  const height = field.length;
  return { x: width, y: height };
}

function forEachAround(
  field: Field,
  point: IPoint,
  cb: (cell: ICell, p: IPoint) => any
) {
  const { y: height, x: width } = sizeOf(field);

  for (let y = point.y - 1; y <= point.y + 1; y++) {
    for (let x = point.x - 1; x <= point.x + 1; x++) {
      if (
        x < 0 ||
        x >= width ||
        y < 0 ||
        y >= height ||
        (x === point.x && y === point.y)
      ) {
        continue;
      }
      cb(field[y][x], { x, y });
    }
  }
}

function putMines(field: Field, mines: number) {
  const { y: height, x: width } = sizeOf(field);

  let minesLeft = mines;
  while (minesLeft > 0) {
    const y = Math.floor(Math.random() * height);
    const x = Math.floor(Math.random() * width);
    const cell = field[y][x];
    if (cell.isMine) {
      continue;
    }

    cell.isMine = true;
    forEachAround(field, { x, y }, (c: ICell) => {
      c.minesAround++;
    });
    minesLeft--;
  }
}

function createRow(size: number): ICell[] {
  const cells = [];
  cells.length = size;

  for (let x = 0; x < size; x++) {
    cells[x] = {
      isMine: false,
      minesAround: 0,
      state: CellState.UNKNOWN,
    };
  }

  return cells;
}

export function createField(size: IPoint, mines: number): Field {
  const field: Field = [];
  field.length = size.y;
  for (let y = 0; y < size.y; y++) {
    field[y] = createRow(size.x);
  }

  putMines(field, mines);

  return field;
}

export function toggleFlagOnCell(field: Field, { x, y }: IPoint) {
  switch (field[y][x].state) {
    case CellState.FLAGGED:
      field[y][x] = { ...field[y][x], state: CellState.UNKNOWN };
      break;
    case CellState.UNKNOWN:
      field[y][x] = { ...field[y][x], state: CellState.FLAGGED };
      break;
  }
}

export function openCell(field: Field, { x, y }: IPoint): GameState {
  if (field[y][x].state === CellState.OPENED) {
    return GameState.RUNNING;
  }

  field[y][x] = { ...field[y][x], state: CellState.OPENED };

  if (!field[y][x].isMine && field[y][x].minesAround === 0) {
    forEachAround(field, { x, y }, (cell, p) => {
      if (!cell.isMine && cell.state !== CellState.OPENED) {
        openCell(field, p);
      }
    });
  }

  if (field[y][x].isMine) {
    return GameState.LOST;
  }

  const won =
    field.find(
      (col) =>
        col.find((c) => !c.isMine && c.state !== CellState.OPENED) != null
    ) == null;

  if (won) {
    return GameState.WON;
  }

  return GameState.RUNNING;
}
