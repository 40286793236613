import Konva from "konva";
import React from "react";
import { Group } from "react-konva";
import { CellView } from "./CellView";
import { Theme } from "./Themes";
import { CellState, ICell, IPoint } from "./Field";

interface ICellProps {
  x: number;
  y: number;
  endGame: boolean;
  cell: ICell;
  margins: IPoint;
  side: number;
  onOpen: (p: IPoint) => void;
  onToggle: (p: IPoint) => void;
  theme: Theme;
}

interface ICellTextData {
  text: string | null;
  color: string;
}

function getCellTextData(
  theme: Theme,
  cell: ICell,
  endGame: boolean = false
): ICellTextData {
  if (cell.state === CellState.OPENED && cell.isMine) {
    return {
      text: theme.Icons.MAIN_MINE,
      color: theme.Colors.MAIN_MINE_BG_COLOR,
    };
  } else if (cell.state === CellState.OPENED) {
    return {
      text: cell.minesAround > 0 ? `${cell.minesAround}` : null,
      color: theme.Colors.OPENED_BG_COLOR,
    };
  } else if (endGame && cell.isMine) {
    return { text: theme.Icons.MINE, color: theme.Colors.MINE_BG_COLOR };
  } else if (cell.state === CellState.FLAGGED) {
    return { text: theme.Icons.FLAG, color: theme.Colors.FLAG_BG_COLOR };
  }

  return { text: null, color: theme.Colors.DEFAULT_BG_COLOR };
}

export class Cell extends React.PureComponent<ICellProps> {
  public render() {
    const { theme, cell, x, y, margins, side, endGame } = this.props;
    const { text, color } = getCellTextData(theme, cell, endGame);

    const posX = margins.x + x * side;
    const posY = margins.y + y * side;

    return (
      <Group
        onClick={this.open}
        onTap={this.open}
        onTouchEnd={this.toggleFlag}
        onWheel={this.toggleFlag}
        onContextMenu={this.toggleFlag}
        clearBeforeDraw={false}
      >
        <CellView
          theme={theme}
          color={color}
          posX={posX}
          posY={posY}
          side={side}
          text={text}
        />
      </Group>
    );
  }

  private open = (e: Konva.KonvaEventObject<any>) => {
    if (e.evt?.button === 2) return;

    const { x, y, onOpen, endGame, cell } = this.props;
    if (!endGame && cell.state !== CellState.FLAGGED) {
      onOpen({ x, y });
    }

    if (!endGame) {
      e.cancelBubble = true;
      e.evt?.preventDefault?.();
    }
  };

  private toggleFlag = (e: Konva.KonvaEventObject<any>) => {
    const { x, y, onToggle, endGame } = this.props;
    if (!endGame) {
      onToggle({ x, y });
    }

    e.cancelBubble = true;
    e.evt?.preventDefault?.();
  };
}
